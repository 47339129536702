<template>
  <div
    ref="rightBarIcon"
    class="md:w-10 md:h-10 w-9 h-9 xl:hidden bg-primary-purple-50 button-shadow flex justify-center items-center cursor-pointer relative rounded-lg z-20"
    @click.prevent="mobileRightBar"
  >
    <icon :icon="rightBarToggleIcon" :color="iconColor" />
    <div v-if="tabRightNav || rightbarHidden" class="xl:hidden flex">
      <UiOnClickOutside :do="closeMobileRightBar">
        <RightBar
          :class="[
            {
              mobileRightBar: isMobileRightBar,
              'ltr:ml-0 rtl:mr-0': tabRightNav,
            },
          ]"
          :mobile-right-bar-height="mobileRightBarHeight"
          :right-bar-visibility="isMobileRightBar"
        />
      </UiOnClickOutside>
    </div>
  </div>
  <div
    v-if="tabRightNav && isMobileRightBar && isRightbarHidden"
    class="rightbar-overlay absolute"
  ></div>
</template>

<script>
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import { mapState } from 'vuex'
import icon from '@components/icons/icon.vue'
import defaultLayout from '@/src/router/layouts/defaultLayout.json'

import RightBar from '@layouts/rightBar.vue'

export default {
  components: {
    RightBar,
    icon,
    UiOnClickOutside,
  },
  data() {
    return {
      isRightbarHidden: window.matchMedia('(max-width: 1279px)').matches,
      mobileRightBarHeight: '',
    }
  },
  computed: {
    ...mapState({
      tabRightNav: (state) => state?.layout?.tabRightNav,
    }),
    rightBarToggleIcon() {
      return this.tabRightNav ? 'doubleChevronLeft' : 'doubleChevronRight'
    },
    isMobileRightBar() {
      return this.rightbarHidden ? false : this.tabRightNav
    },
    rightbarHidden() {
      let isRightBarActive
      isRightBarActive = defaultLayout.RightBarUnactive.includes(this.$route?.name)
      if (!isRightBarActive)
        return (isRightBarActive = defaultLayout.RightBarActive.includes(this.$route?.path))
      return isRightBarActive
    },
    iconColor() {
      return this.tabRightNav ? 'primary-purple-700' : 'primary-gray-700'
    },
  },
  mounted() {
    window.addEventListener('resize', this.adjustAbsoluteDivHeight)
    if (this.tabRightNav) {
      this.adjustAbsoluteDivHeight()
    }
  },
  methods: {
    adjustAbsoluteDivHeight() {
      let parentContainer = this.$refs['rightBarIcon']
      if (parentContainer) {
        let parentRect = parentContainer.getBoundingClientRect()
        let bottomOffset = window.innerHeight - parentRect.top - parentRect.height
        this.mobileRightBarHeight = bottomOffset - 50
      }
    },

    mobileRightBar() {
      if (window.matchMedia('(max-width: 1279px)').matches && !this.tabRightNav) {
        this.adjustAbsoluteDivHeight()
        this.$store.commit('layout/SET_TAB_RIGHT_BAR', true)
      }
    },
  },
}
</script>

<style scoped>
.rightbar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--overlay);
  transition: 0.5s;
}
</style>
