<template>
  <div v-if="crumbs.length" class="breadcrumbs flex flex-wrap">
    <span
      class="flex items-center mobile_text md:hidden font-rubik text-text-color text-xs mt-0.5 capitalize"
    >
      home
    </span>
    <span v-for="(crumb, i) in crumbs" :key="i" class="flex items-center gap-0.5">
      <router-link v-if="i < crumbs.length - 1" :to="crumb.path">
        <span v-if="crumb.name === 'default'" class="md:block hidden text-xs font-inter">
          <span v-if="currentCampusScope && currentSectionScope" class="text-primary-gray-400">
            Section
          </span>
          <span v-else-if="currentCampusScope" class="text-primary-gray-400">Campus</span>
          <span v-else class="text-primary-gray-400">Institute</span>
        </span>
        <span
          v-else
          class="font-inter font-normal text-primary-gray-400 mobile_text text-xs capitalize"
        >
          {{ $t(`breadcrumb.${crumb.name}`) }}
        </span>
      </router-link>
      <span v-else class="font-inter font-normal mobile_text text-xs capitalize text-text-color">
        {{ $t(`breadcrumb.${crumb.name}`) }}
      </span>
      <span
        v-if="i < crumbs.length - 1"
        class="text-xs forward-slash text-primary-gray-400 sm:text-base"
      >
        <icon class="icon" icon="chevronLeft" color="primary-gray-400" height="16" width="16" />
      </span>
    </span>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getDefaultRoute } from '@utils/generalUtil'
import icon from '@components/icons/icon.vue'

export default {
  name: 'BreadCrumbs',
  components: {
    icon,
  },
  props: {
    root: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('layout', ['currentCampusScope', 'currentSectionScope', 'leftBarContent']),
    ...mapGetters('layout', ['userRole']),
    crumbs() {
      let path = ''
      let title = this.root || 'default'
      let defaultRoute = getDefaultRoute(
        this.userRole,
        this.currentCampusScope,
        this.currentSectionScope,
        this.leftBarContent?.defaultRoute,
      )
      let resolvedRoute = this.$router?.resolve({ name: defaultRoute })
      const cs = [{ name: title, path: resolvedRoute.href }]
      if (!this.$route) return []

      const r = this.$route?.path?.split('/')
      const m = (this.$route?.matched[0]?.meta?.crumbs || '').split('/')
      const dynamicPathArray = Object.values(this.$route.params)
      for (var i = 1; i < r.length; i++) {
        var name = m[i] || r[i]
        if (r[i] === '') continue

        title += ' : ' + name
        path += '/' + name
        if (dynamicPathArray.includes(name)) continue

        cs.push({ name: name, path: path })
      }
      return cs
    },
  },
}
</script>

<style lang="scss" module>
.breadcrumpbs {
  span {
    span,
    a {
      width: max-content;
      font-size: 12px;

      /* @include font("Rubik", 0.8em, normal); */
      // color:  $menu;
      color: var(--menu);
      text-decoration: none;
      text-transform: capitalize;

      /* @include media(null, 575px) {
          display: none;
        } */
    }
  }
}
.currentPage {
  font-size: 11px;
  // color: $label-text;
  color: var(--label-text);
}
.positionRelative {
  position: relative;
  top: 2px;
}
</style>

<style lang="scss" scoped>
@media only screen and (max-width: 640px) {
  .mobile_text {
    margin-top: 3px;
  }
}
@media only screen and (max-width: 500px) {
  .mobile_text {
    margin-top: 5px;
    font-size: 11px;
  }
  .forward-slash {
    margin-top: 4px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 375px) {
  .mobile_text {
    margin-top: 4px;
    font-size: 10px;
  }
  .forward-slash {
    margin-top: 3px;
    font-size: 10px;
  }
}
@media only screen and (max-width: 350px) {
  .mobile_text {
    margin-top: 6px;
    font-size: 8px;
  }
  .forward-slash {
    margin-top: 5px;
    font-size: 9px;
  }
}
</style>
