<template>
  <div
    class="badge relative bg-white border border-primary-gray-100 rounded-md text-sm h-6 flex"
    :class="childClass"
    @mouseover="showSingleItem()"
    @mouseleave="hideSingleItem()"
  >
    <div class="items-center flex px-2 truncate overflow-auto">
      <div class="rounded-full flex justify-center items-center">
        <div v-if="image" class="w-4 h-4 rounded-full">
          <img v-if="url !== null" :src="`${url}`" class="h-full w-full rounded-full" />
          <div
            v-else-if="url === null"
            class="h-full w-full rounded-full flex justify-center items-center text-[8px] bg-primary-purple-50 text-primary-purple-600"
          >
            {{ initials(nameInitials) }}
          </div>
        </div>
      </div>
      <div class="truncate ml-1 pr-1 rounded-full capitalize">
        {{ title?.replaceAll('_', ' ') || GENERAL_CONSTANTS.NOT_APPLICABLE }}
      </div>
    </div>
    <div v-if="singleSelectedItem" class="absolute z-10" :class="dropdownTopBottom">
      <Tooltip
        class="w-min sm:w-auto"
        :title="tooltipTitle || title"
        :text="description"
        direction="left"
        :tooltip="tooltip"
      />
    </div>
  </div>
</template>

<script>
import Tooltip from '@src/components/tooltip.vue'
import generalUtil from '@src/mixins/general-mixins.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export default {
  name: 'UiMultiSelectBox',
  components: {
    Tooltip,
  },
  mixins: [generalUtil],

  props: {
    childClass: {
      type: String,
      default: '',
    },
    dropdownTopBottom: {
      type: String,
      default: 'ltr:-left-4 rtl:-right-4 top-12',
    },
    tooltip: {
      type: String,
      default: '',
    },
    profile: {
      type: Boolean,
      default: false,
    },
    url: { type: String, default: 'title' },
    nameInitials: { type: String, default: 'title' },

    title: { type: [String, Number], default: '' },
    tooltipTitle: { type: [String, Number], default: '' },
    description: { type: [String, Number], default: '' },
    image: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      GENERAL_CONSTANTS,
      singleSelectedItem: false,
    }
  },

  methods: {
    /**
     * Show Single Item
     * @description Show single chip detailed tooltip
     */
    showSingleItem() {
      this.singleSelectedItem = true
    },
    /**
     * Hide Single Item
     * @description Hide single chip detailed tooltip
     */
    hideSingleItem() {
      this.singleSelectedItem = false
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: start;
  list-style: none;
}

.badge .tooltip-text {
  &::before {
    position: absolute;
    top: -7px;
    width: 13px;
    height: 13px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--text-color-info);
    border-left: 1px solid var(--text-color-info);
    transform: rotate(45deg);
  }

  position: absolute;
  top: 35px;
  z-index: 1;
  min-height: 40px;
  padding: 5px;
  margin-left: 5px;
  overflow-y: hidden;
  color: rgb(12, 12, 12);
  text-align: left;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  box-shadow: 0 0 3px 0.1px;
  transition: opacity 0.3s;
}
</style>
