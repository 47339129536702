<template>
  <ValidationObserver @submit="applyFilters">
    <div class="w-full md:mt-0 mt-2.5">
      <div class="flex relative flex-col justify-between items-start">
        <!-- Heading -->
        <div class="flex w-full items-center">
          <div class="w-full">
            <div v-if="pageHeading" class="flex flex-wrap xl:pb-[3px] items-center">
              <span
                class="font-inter relative font-medium text-sm md:text-base xl:text-xl text-black"
              >
                {{ $t(`title.${pageHeading}`) }}
              </span>
              <UiOnClickOutside :do="closeToolTip">
                <span
                  class="ml-2 rtl:mr-2 cursor-pointer"
                  @click="clickToolTip"
                  @mouseover="displayToolTip"
                  @mouseleave="hideToolTip"
                >
                  <icon
                    class="icon"
                    icon="infoCircleUpdated"
                    color="primary-purple-600"
                    height="20.617"
                    width="16.5"
                  />
                  <div
                    v-if="showToolTip"
                    class="absolute -ml-4 mt-3 md:mt-2 rtl:-mr-24 md:rtl:-mr-7 z-20 max-w-2"
                    :class="$style.toolTip"
                  >
                    <Tooltip
                      :title="$t(`title.${tooltipTitle}`)"
                      :text="$t(`toolTipInfo.${tooltipTitle}`)"
                      direction="left"
                    />
                  </div>
                </span>
              </UiOnClickOutside>
            </div>
            <BreadCrumbs v-if="displayBreadcrumb" class="breadcrumpbs" :display-breadcrumb="true" />
          </div>
          <div v-if="!rightbarHidden" class="flex items-center justify-end gap-2.5 w-auto">
            <!-- Filters -->
            <div class="flex items-center gap-3" :class="[!tabRightNav && 'z-30']">
              <div
                v-if="filterOption && isEnabledForProd"
                class="xl:h-11 xl:w-[104px] md:w-10 md:h-10 w-9 h-9 flex items-center cursor-pointer justify-center gap-2 bg-white border rounded-lg"
                :class="filterBorderColor"
                @click="toggleFilter"
              >
                <icon
                  icon="filter"
                  :height="filterIconSize"
                  :width="filterIconSize"
                  :color="filterIconColor"
                />
                <span
                  class="font-inter font-semibold text-primary-gray-700 text-sm hidden xl:block"
                  :class="filterTextColor"
                >
                  Filters
                </span>
              </div>
              <div
                v-if="count"
                class="badge z-1 relative justify-center flex items-center bg-primary-purple-600 border mb-5 border-primary-purple-600 text-white rounded-full text-sm w-6 h-6 rtl:-mr-7 ltr:-ml-7"
              >
                <p class="items-center text-white p-1.5">{{ count }}</p>
              </div>
            </div>

            <div
              v-if="isButtonsAvailables && !isMobileScreen"
              class="flex items-center gap-2.5 xl:hidden"
            >
              <div v-if="rightBarContent.header" class="text-center">
                <div
                  v-for="(button, index) in rightBarContent.header.buttons"
                  :key="index + button.title"
                  class="flex gap-2.5"
                >
                  <SplitButton v-if="!index" v-permission="button.permissions" :buttons="button" />
                </div>
              </div>
            </div>
            <div v-if="isStatsAvailable">
              <MobileRightBar />
            </div>
          </div>
        </div>
        <div v-if="filterOption" class="filter-block w-full">
          <transition name="slide-fade">
            <div
              v-if="isActive"
              class="mt-2 bg-white border border-primary-purple-200 rounded-lg relative px-4.5 sm:px-5 py-4 sm:py-[17.5px]"
            >
              <span class="absolute z-10 cursor-pointer top-3.5 right-[18px]" @click="toggleFilter">
                <icon class="icon" icon="cross" height="14" width="14" color="primary-purple-700" />
              </span>
              <div>
                <slot name="filterItems"></slot>
              </div>
              <div
                v-if="displayedColumnOptions?.length"
                :key="forceRerender + displayedColumnOptions.length"
                class="pb-5 border-b border-primary-purple-100"
              >
                <UiMultiSelect
                  v-model="selectedColumns"
                  title="SELECT_COLUMNS"
                  label="dataTableName"
                  input-color="bg-primary-white"
                  :options="displayedColumnOptions"
                  :already-selected="alreadySelectedDisplayedColumn"
                  class="text-text-color font-inter text-sm font-normal flex-1"
                />
              </div>
              <div
                class="footer flex justify-end pt-5"
                :class="[!displayedColumnOptions?.length && 'border-t border-primary-purple-100']"
              >
                <div class="flex w-full justify-between">
                  <div>
                    <UIButton class="sm-button" type="button" @click="emitClearEvent">
                      Clear
                    </UIButton>
                  </div>
                  <div class="flex gap-5">
                    <UIButton class="sm-button" type="button" @click="emitCancelEvent">
                      Cancel
                    </UIButton>
                    <UIButton class="sm-button" button="primary">Apply</UIButton>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <!-- Class dropdown -->
        <div class="md:hidden mt-2.5 flex gap-2.5 w-full">
          <div v-if="showClassDropdown" class="flex-1">
            <ClassDropdown />
          </div>
          <div v-if="showSectionDropdown" class="flex-1">
            <SectionDropdown />
          </div>
        </div>
      </div>
      <!-- Buttons -->
      <div
        v-if="isButtonsAvailables && !rightbarHidden"
        class="flex items-center justify-end gap-2.5 xl:hidden"
      >
        <div class="flex flex-wrap justify-end">
          <div
            v-for="(button, index) in rightBarContent.header?.buttons"
            :key="index + button.title"
          >
            <SplitButton
              v-if="index || (!index && isMobileScreen)"
              v-permission="button.permissions"
              :buttons="button"
              class="ml-2.5 xl:mt-0 mt-2.5 lg:mb-0"
            />
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import MobileRightBar from '@src/components/MobileRightBar.vue'
import BreadCrumbs from '@components/BreadCrumbs.vue'
import icon from '@components/icons/icon.vue'
import { mapState } from 'vuex'
import SectionDropdown from '@/src/router/layouts/left-bar/SectionDropdown.vue'
import ClassDropdown from '@src/router/layouts/left-bar/ClassDropdown.vue'
import { Form as ValidationObserver } from 'vee-validate'
import Tooltip from '@components/tooltip.vue'
import tooltipMixin from '@src/mixins/components/tooltip-mixin.js'
import { FEATURES_ENVIRONMENT, ALLOWED_FILTERS } from '@src/services/.env.js'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
import defaultLayout from '@/src/router/layouts/defaultLayout.json'
import SplitButton from '@components/SplitButton.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'

export default {
  name: 'FeedTitleWrapper',
  components: {
    BreadCrumbs,
    ValidationObserver,
    icon,
    MobileRightBar,
    SectionDropdown,
    ClassDropdown,
    Tooltip,
    UiMultiSelect,
    UiOnClickOutside,
    SplitButton,
    UIButton,
  },
  mixins: [tooltipMixin],
  props: {
    count: {
      type: Number,
      default: 0,
    },
    statsTitle: {
      type: String,
      default: 'Title',
    },
    title: {
      type: String,
      default: '',
    },
    tooltipTitle: {
      type: String,
      default: '',
    },

    description: {
      type: String,
      default: '',
    },
    displayBreadcrumb: {
      type: Boolean,
      default: false,
    },
    filterOption: {
      type: Boolean,
      default: false,
    },
    displayedColumnOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumn: {
      type: Array,
      default: () => [],
    },

    filterFooterClasses: {
      type: String,
      default: 'border-t border-primary-grey p-5',
    },
  },
  emits: ['clear', 'cancel', 'applyFilter', 'showFields'],
  data() {
    return {
      isNotTablet: false,
      pageHeading: '',
      isActive: false,
      dashboard: 'dashboard',
      selectedColumns: [],
      forceRerender: 0,
      isRightbarHidden: window.matchMedia('(max-width: 1279px)').matches,
      isMobileScreen: window.matchMedia('(max-width: 767px)').matches,
    }
  },

  computed: {
    ...mapState({
      tabRightNav: (state) => state?.layout?.tabRightNav,
      tabLeftNav: (state) => state?.layout?.tabLeftNav,
      classId: (state) => state?.layout?.currentSectionScope,
      showSectionDropdown: (state) => state.layout.showSectionDropdown,
      showClassDropdown: (state) => state.layout.showClassDropdown,
      currentCampusScope: (state) => state?.layout?.currentCampusScope,
      rightBarContent: (state) => state.layout.rightBarContent,
    }),
    filterBorderColor() {
      return this.isActive ? 'border-primary-purple-700' : 'border-primary-purple-200'
    },
    filterIconColor() {
      return this.isActive ? 'primary-purple-700' : 'primary-gray-700'
    },
    filterTextColor() {
      return this.isActive ? 'text-primary-purple-700' : 'text-primary-gray-700'
    },
    rightBarToggleIcon() {
      return this.tabRightNav ? 'doubleChevronLeft' : 'doubleChevronRight'
    },
    isButtonsAvailables() {
      return this.rightBarContent?.header?.buttons?.length
    },
    iconColor() {
      return this.tabRightNav ? 'primary-purple-700' : 'primary-gray-700'
    },
    isStatsAvailable() {
      return this.rightBarContent?.stats
    },
    rightbarHidden() {
      let isRightBarActive
      isRightBarActive = defaultLayout.RightBarUnactive.includes(this.$route?.name)
      if (!isRightBarActive)
        return (isRightBarActive = defaultLayout.RightBarActive.includes(this.$route?.path))
      return isRightBarActive
    },
    isEnabledForProd() {
      let isEnabled = false

      if (!['dev', 'prod'].includes(FEATURES_ENVIRONMENT)) return !isEnabled

      isEnabled = ALLOWED_FILTERS.includes(this.$route?.name)

      return isEnabled
    },
    isIntituteLevelChange() {
      return this.currentCampusScope + this.classId
    },
    filterIconSize() {
      return this.isRightbarHidden ? '20' : '24'
    },
    isSecondaryButtonsDisplay() {
      return this.rightBarContent?.header?.buttons?.length > 1 && !this.rightbarHidden
    },
  },
  watch: {
    isIntituteLevelChange: {
      handler() {
        this.forceRerender++
      },
    },
  },
  /**
   * Created Hook
   * @description Sets the title of page
   */
  created() {
    if (this.$route && this.$route.meta.pageHeading) {
      this.pageHeading = this.$route.meta.pageHeading
    } else {
      this.pageHeading = this.title || ''
    }
  },

  methods: {
    emitClearEvent() {
      this.toggleFilter()
      this.$emit('clear')
    },
    emitCancelEvent() {
      this.toggleFilter()
      this.$emit('cancel')
    },

    applyFilters() {
      this.$emit('applyFilter', { displayedColumns: this.selectedColumns })
      this.$emit('showFields')
      this.toggleFilter()
    },
    /**
     * toggle filter
     * @description toggling the value of isActive data variable
     */
    toggleFilter() {
      this.isActive = !this.isActive
    },
  },
}
</script>

<style lang="scss" module>
.iconPosition {
  top: 14px;
  right: 14px;
}
</style>

<style scoped>
.sm-button {
  min-width: 125px;
  font-weight: 500;
  line-height: 28px;
}
@media screen and (max-width: 1280px) {
  .sm-button {
    min-width: 110px;
    min-height: 40px;
  }
}
@media screen and (max-width: 640px) {
  .sm-button {
    min-width: 90px;
    min-height: 36px;
  }
}
</style>
