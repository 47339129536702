export default {
  methods: {
    /**
     * Close dropdown when click outside
     */
    closeDropdown() {
      this.showDropdown = false
    },

    /**
     * Toggle Dropdown
     * @description Toggles Select box dropdown
     */
    toggleDropdown() {
      this.isVisible = !this.isVisible
    },
  },
}
