<template>
  <div v-if="image">
    <div v-if="image && item.image !== null" class="w-7 h-7">
      <img :src="item.image" class="h-full w-full rounded-full" />
    </div>
    <span
      v-else
      class="h-7 w-7 rounded-full bg-primary-purple-50 flex justify-center items-center text-xs text-primary-purple-600"
    >
      {{ initials(`${item.first_name} ${item.last_name}`) }}
    </span>
  </div>
</template>

<script>
import generalMixin from '@src/mixins/general-mixins.js'

export default {
  mixins: [generalMixin],
  props: {
    image: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
}
</script>
